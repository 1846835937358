<template>
  <div id="website">
    <!-- <div class="top-container">
      <div class="top-wrapper">
        <div>indiba.com</div>
        <ul>
          <li><i class="iconfont icon-xindiantu" /> COVID-19</li>
          <li><i class="iconfont icon-weizhi" /> 找到INDIBA</li>
          <li>
            <i class="iconfont icon-changjianwentixiangguanwenti2" /> 问题解答
          </li>
          <li><i class="iconfont icon-shenfenzheng" /> Life @ INDIBA</li>
          <li><i class="iconfont icon-duoyuyan" /> 语言</li>
          <li title="搜索"><i class="iconfont icon-sousuo" /></li>
        </ul>
      </div>
    </div>
    <div class="show-video">
      <iframe
        class="iframe-video"
        src="https://player.vimeo.com/video/696279791?h=7ca3ade632&title=0&byline=0&autoplay=1&dnt=1&loop=1&background=1&app_id=122963"
        frameborder="0"
      ></iframe>
      <video id="myvideo" width="100%" muted="muted" autoplay loop>
        <source src="../../assets/video/background.mp4" type="video/mp4" />
      </video>
    </div> -->
    <div style="width: 100%">
      <div class="bg-img"></div>
      <!-- <video id="myvideo" muted="muted" autoplay loop>
        <source src="../../assets/video/background.mp4" type="video/mp4" />
      </video> -->
      <div class="nav_bar">
        <div class="logo">
          <img src="../../assets/home/logo.png" alt="" />
        </div>
        <ul>
          <!-- <li><a href="#page1">关于INDIBA</a></li>
          <li><a href="#page2">产品方案</a></li>
          <li><a href="#page3">INDIBA资讯</a></li>
          <li><a href="#page3">找到INDIBA</a></li> -->
          <li><router-link to="/home#page3">关于INDIBA</router-link></li>
          <li><router-link to="/home#page4">产品方案</router-link></li>
          <li><a>INDIBA资讯</a></li>
          <li><router-link to="/found">找到INDIBA</router-link></li>
          <li><router-link to="/china">INDIBA中国</router-link></li>
          <li><router-link to="">名人堂</router-link></li>
        </ul>
        <div class="concact_tel">
          <a href="tel://010 85951863">
            <i class="ri-phone-line" style="margin-right: 8px"></i>
            010 85951863
          </a>
        </div>
      </div>
    </div>
    <div class="frame-container">
      <div class="fame-wrapper">
        <h1>名人堂</h1>
        <h5>名人堂为各领域受益的运动员来自INDIBA技术的顶尖选手而设立。</h5>
        <img src="../../assets/img/logo-en.png" alt="Hall Of Fame" />
      </div>
    </div>

    <div class="btn-wrapper">
      <div class="btn-container">
        <div class="btn btn-actived">全部</div>
        <div class="btn">田径</div>
        <div class="btn">篮球</div>
        <div class="btn">自行车</div>
        <div class="btn">足球</div>
        <div class="btn">手球</div>
        <div class="btn">赛车</div>
        <div class="btn">游泳</div>
        <div class="btn">网球</div>
      </div>
    </div>
    <div class="person-wrapper">
      <div class="person-container">
        <div class="personal">
          <img src="../../assets/img/character/bg1.jpg" />
          <div class="personal-des">
            <i>足球</i>
            <p class="personal-name">费兰·托雷斯</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/3ugooGzf4TWVhgKQk_SUEA"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg2.jpg" />
          <div class="personal-des">
            <i>足球</i>
            <p class="personal-name">安德雷斯·伊涅斯塔</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/xHgEZRd2j1lNKznZufBQYQ"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg3.jpg" />
          <div class="personal-des">
            <i>网球</i>
            <p class="personal-name">拉菲尔·纳达尔</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/6neNdSez8PRIHGKuf3XOnQ"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg4.jpg" />
          <div class="personal-des">
            <i>篮球</i>
            <p class="personal-name">西班牙篮球联合会</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg5.jpg" />
          <div class="personal-des">
            <i>田径</i>
            <p class="personal-name">法国田径运动联合会</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg6.jpg" />
          <div class="personal-des">
            <i>篮球</i>
            <p class="personal-name">帕纳辛纳斯科队</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/jl463H1NPuy1KH6zu7ka2w"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg7.jpg" />
          <div class="personal-des">
            <i>铁人三项</i>
            <p class="personal-name">哈维·戈麦斯·诺亚</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg8.jpg" />
          <div class="personal-des">
            <i>自行车</i>
            <p class="personal-name">阿斯塔纳自行车队</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg9.jpg" />
          <div class="personal-des">
            <i>网球</i>
            <p class="personal-name">加尔比妮·穆古拉扎</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/e7hNh2iA41ujOGleycXeHw"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg10.jpg" />
          <div class="personal-des">
            <i>自行车</i>
            <p class="personal-name">西班牙移动之星自行车队</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg11.jpg" />
          <div class="personal-des">
            <i>田径</i>
            <p class="personal-name">跑步俱乐部</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/VW1xc4IBU-IV0xHSH_2TwQ"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg12.jpg" />
          <div class="personal-des">
            <i>摩托车</i>
            <p class="personal-name">亚历克斯·埃斯帕加罗</p>
            <p class="read-more">
              <a
                href="https://mp.weixin.qq.com/s/kiiZepbAXBHgiDN8bXVJwQ"
                target="_blank"
                >获取更多 ></a
              >
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg13.jpg" />
          <div class="personal-des">
            <i>手球</i>
            <p class="personal-name">法国国家手球队</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg14.jpg" />
          <div class="personal-des">
            <i>自行车</i>
            <p class="personal-name">奈洛·金塔纳</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg15.jpg" />
          <div class="personal-des">
            <i>田径</i>
            <p class="personal-name">克里斯托夫·勒迈特雷</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg16.jpg" />
          <div class="personal-des">
            <i>摩托车</i>
            <p class="personal-name">亚历克斯·林斯</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
        <div class="personal">
          <img src="../../assets/img/character/bg17.jpg" />
          <div class="personal-des">
            <i>游泳</i>
            <p class="personal-name">杰茜卡·瓦尔</p>
            <p class="read-more">
              <a href="javascript:;">获取更多 ></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hall",
  components: {},

  data() {
    return {};
  },
  created() {
    document.title = "名人堂";
  },

  
};
</script>

<style lang="less" scoped>
body,
#website {
  //   min-width: 1280px;
  width: 100%;
  @font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  user-select: none;
  box-sizing: border-box;
  //   #myvideo {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     // min-height: 50%;
  //     // min-width: 50%;
  //     z-index: -100;
  //   }
  .bg-img {
    width: 100%;
    height: 400px;
    background-image: url(../../assets/home/first_bg003.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .nav_bar {
    width: 100%;
    position: absolute;
    z-index: 2022;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    line-height: 48px;
    padding: 55px 80px;
    li {
      display: inline-block;
    }
    .logo {
      cursor: pointer;
    }

    a {
      line-height: 40px;
      color: #fff;
      padding: 20px;
      &:hover {
        // background-color: #060;
      }
    }

    .concact_tel {
      a {
        display: flex;
        align-items: center;
      }
    }
  }
  .top-container {
    background-color: #005670;
    height: 40px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    .top-wrapper {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      ul {
        flex: 1;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        li {
          padding: 0 10px;
          line-height: 40px;
          height: 100%;
          cursor: pointer;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
  .frame-container {
    width: 100%;
    background-color: #fff;
    // margin-top: 500px;
    .fame-wrapper {
      width: 80%;
      padding: 80px 0;
      margin: 0 auto;
      position: relative;
      h1 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0;
        width: 80%;
      }
      h5 {
        font-size: 20px;
        color: #666;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        width: 80%;
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 139px;
      }
    }
  }
  .btn-wrapper {
    width: 100%;
    background-color: #e7e9ea;
    .btn-container {
      width: 80%;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .btn {
        min-width: 120px;
        font-weight: 700;
        margin: 10px;
        background-color: #fff;
        border-color: #fff;
        padding: 20px 40px;
        text-transform: uppercase;
        border-radius: 5px;
        cursor: pointer;
        color: #005670;
        text-align: center;
        &:hover {
          color: #222;
          background-color: #fcfcfc;
        }
      }
      .btn-actived {
        background-color: #52cec6;
        color: #fff;
      }
    }
  }
  @media screen and (min-width: 1400px) {
    .person-wrapper {
      background-color: #fff;
      // margin-top: 50px;
      padding-top: 50px;
      .person-container {
        width: 1400px;
        margin: 0 auto;
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 22% 22% 22% 22%;
        //   grid-column-gap: 10px;
        justify-content: space-between;

        .personal {
          width: 300px;
          justify-self: center;
          cursor: pointer;
          position: relative;
          border-radius: 10px;
          margin: 10px 0;
          height: 400px;
          //   background-color: #52cec6;
          //   background-image: url(../../assets/img/character/bg1.jpg);
          //   background-position: center;
          //   background-size: cover;
          overflow: hidden;
          //   filter: grayscale(100%);
          transition: all 0.5s;
          img {
            width: 100%;
          }
        }
        .personal:hover {
          //   filter: grayscale(0%);
        }
        .personal:hover .personal-des {
          transform: translateY(0px);
        }
        .personal-des {
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.6)
          );
          padding: 0 20px;
          color: #eee;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          transform: translateY(40px);
          transition: all 0.5s;
          .personal-name {
            font-size: 24px;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 20px;
            color: #fff;
            line-height: 28px;
          }
          .read-more {
            font-size: 18px;
            color: #00bfb3;
            a {
              color: #00bfb3;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1399px) and (min-width: 1200px) {
    .person-wrapper {
      background-color: #fff;
      // margin-top: 50px;
      padding-top: 50px;
      .person-container {
        width: 1200px;
        margin: 0 auto;
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 33% 33% 33%;
        //   grid-column-gap: 10px;
        justify-content: space-evenly;
        //   align-items: center;

        .personal {
          width: 300px;
          cursor: pointer;
          justify-self: center;
          position: relative;
          border-radius: 10px;
          margin: 10px 0;
          height: 400px;
          //   background-color: #52cec6;
          //   background-image: url(../../assets/img/character/bg1.jpg);
          //   background-position: center;
          //   background-size: cover;
          overflow: hidden;
          //   filter: grayscale(100%);
          transition: all 0.5s;
          img {
            width: 100%;
          }
        }
        .personal:hover {
          //   filter: grayscale(0%);
        }
        .personal:hover .personal-des {
          transform: translateY(0px);
        }
        .personal-des {
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.6)
          );
          padding: 0 20px;
          color: #ccc;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          transform: translateY(40px);
          transition: all 0.5s;
          .personal-name {
            font-size: 24px;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 20px;
            color: #fff;
            line-height: 28px;
          }
          .read-more {
            font-size: 18px;
            color: #00bfb3;
            a {
              color: #00bfb3;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .person-wrapper {
      background-color: #fff;
      // margin-top: 50px;
      padding-top: 50px;
      .person-container {
        width: 800px;
        margin: 0 auto;
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 45% 45%;
        //   grid-column-gap: 10px;
        justify-content: space-evenly;
        //   align-items: center;

        .personal {
          width: 300px;
          cursor: pointer;
          justify-self: center;
          position: relative;
          border-radius: 10px;
          margin: 10px 0;
          height: 400px;
          //   background-color: #52cec6;
          //   background-image: url(../../assets/img/character/bg1.jpg);
          //   background-position: center;
          //   background-size: cover;
          overflow: hidden;
          //   filter: grayscale(100%);
          transition: all 0.5s;
          img {
            width: 100%;
          }
        }
        // .personal:hover {
        //   filter: grayscale(0%);
        // }
        .personal:hover .personal-des {
          transform: translateY(0px);
        }
        .personal-des {
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.6)
          );
          padding: 0 20px;
          color: #ccc;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          transform: translateY(40px);
          transition: all 0.5s;
          .personal-name {
            font-size: 24px;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 20px;
            color: #fff;
            line-height: 28px;
          }
          .read-more {
            font-size: 18px;
            color: #00bfb3;
            a {
              color: #00bfb3;
            }
          }
        }
      }
    }
  }
  // .person-wrapper {
  //   background-color: #fff;
  //   // margin-top: 50px;
  //   padding-top: 50px;
  //   .person-container {
  //     width: 80%;
  //     margin: 0 auto;
  //     display: flex;
  //     flex-wrap: wrap;
  //     // grid-template-columns: 22% 22% 22% 22%;
  //   //   grid-column-gap: 10px;
  //     justify-content: flex-start;
  //   //   align-items: center;

  //     .personal {
  //       min-width: 300px;
  //       cursor: pointer;
  //       position: relative;
  //       border-radius: 10px;
  //       margin: 10px;
  //       height: 400px;
  //       background-color: #52cec6;
  //       background-image: url(../../assets/img/team.jpg);
  //       background-position: center;
  //       background-size: cover;
  //       overflow: hidden;
  //       filter: grayscale(100%);
  //       transition: all 0.5s;
  //     }
  //     .personal:hover {
  //       filter: grayscale(0%);
  //     }
  //     .personal:hover .personal-des {
  //       transform: translateY(0px);
  //     }
  //     .personal-des {
  //       background-image: linear-gradient(
  //         rgba(0, 0, 0, 0.01),
  //         rgba(0, 0, 0, 0.6)
  //       );
  //       padding: 0 20px;
  //       color: #ccc;
  //       position: absolute;
  //       bottom: 0;
  //       left: 0;
  //       right: 0;
  //       transform: translateY(40px);
  //       transition: all 0.5s;
  //       .personal-name {
  //         font-size: 24px;
  //         font-weight: 600;
  //         margin-top: 10px;
  //         margin-bottom: 20px;
  //         color: #fff;
  //         line-height: 28px;
  //       }
  //       .read-more {
  //         font-size: 18px;
  //         color: #00bfb3;
  //       }
  //     }
  //   }
  // }
}
</style>
